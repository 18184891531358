import React, { useEffect, useState } from "react";
import homeicon from "../icons/homeIcon.svg";
import searchIcon from "../icons/searchIcon1.svg";
import notificationIcon from "../icons/notificationIcon.svg";
import postIcon from "../icons/postIcon.svg";
import messageIcon from "../icons/messageIcon.png";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import notifymeIcon from "../icons/notifymeIcon.svg";
import { setlastnote, setmessagelastnote } from "../app/features/profile/profile";
import  "./Navs.css"
import { AiFillHome, AiOutlineSearch, AiOutlineBell, AiOutlineMessage } from "react-icons/ai";
import { IoNotificationsSharp, IoChatbubblesSharp } from "react-icons/io5";
import { useLocation } from "react-router-dom";

export const BottomNav = () => {
  const login = useSelector((state) => state.register.logedin);
  const msgnitocations = useSelector((state) => state.message.msgnitocations);
  const lastmessage = useSelector((state) => state.message.lastmessage);
  const bookingnote = useSelector((state) => state.booking.bookingnote);
  let pushnote = useSelector((state) => state.profile.pushnote);
  let lastmessagenote = useSelector((state) => state.profile.lastmessagenote);
  let lastnote = useSelector((state) => state.profile.lastnote);
  const dispatch = useDispatch()
  const navigate = useNavigate();
  //#4287f5
  const [homecolor, sethomecolor] = useState("#c2d0e1");
  const [modelcolor, setmodelcolor] = useState("");
  const [notificationcolor, setnotificationcolor] = useState("");
  const [messagecolor, setmessagecolor] = useState("");
  const [activeTab, setActiveTab] = useState("home");
  const [viewed, setViewed] = useState(false)
  const location = useLocation();
const allowedMobileRoutes = ["/", "/search", "/modelnotify/allview/", "/allmessage/recentmessage"];
const isAllowedMobileRoute = allowedMobileRoutes.includes(location.pathname);


  const handleNavigation = (tab, path) => {
    setActiveTab(tab);
    navigate(path);

    if (tab === 'notify') {
      setViewed(true)
    }
  };

  const checknotification = () => {
    if (msgnitocations.length > 0) {
      return true;
    } else {
      return false;
    }
  };

  const messagecount = () => {

    const count = msgnitocations.length;
  if (count > 99) {
    return "99+";
  }
  return `${count}`;
    
    // if (msgnitocations.length > 0) {
    //   return `${msgnitocations.length}`;
    // } return "0"
  };

  useEffect(()=>{
    if(pushnote === true){
      console.log("will run notification")
      Notification.requestPermission().then((result)=>{
        if(result === "granted"){
          
        }
      })


    }
  },[pushnote,bookingnote,msgnitocations])

  const notCount = () => {
    //   if ( viewed || !bookingnote) return 0;
    // const total = bookingnote.model.length + bookingnote.notify.length;
    // return total > 99 ? "99+" : total;
    if (bookingnote) {

      return bookingnote.model.length + bookingnote.notify.length
    } else {
      return 0
    }
  }
    
 

  return (
    <div >
      {login && (
        <div
          className={`
            ${isAllowedMobileRoute ? "block md:block" : "hidden md:block"} bg-black py-4 w-screen bottom-0 fixed md:pl-4  flex flex-row place-content-between pb-1
     sm:top-16 sm:left-0 sm:flex-col z-10 sm:h-3/4 sm:bg-black md:w-fit  overflow-hidden  items-center justify-center
    `}
        >
          <div className="flex md:flex-col sm:flex-row  place-content-between md:h-full w-full bottombar">

          <div className="flex flex-col items-center">
          <button
            className="flex flex-col items-center"
            onClick={() => handleNavigation("home", "/")}
          >
            <AiFillHome
              className={`w-8 h-8 ${
                activeTab === "home" ? "text-white" : "text-gray-500"
              }`}
            />
          </button>
          <p className={` text-xs ${activeTab === "home" ? "text-white" : "text-gray-500" }` }>Home</p>
        </div>
          

        <div className="flex flex-col items-center">
          <button
            className="flex flex-col items-center"
            onClick={() => handleNavigation("search", "/search")}
          >
            <AiOutlineSearch
              className={`w-8 h-8 ${
                activeTab === "search" ? "text-white" : "text-gray-500"
              }`}
            />
          </button>
          <p className={` text-xs ${activeTab === "search" ? "text-white" : "text-gray-500" }` }>Search</p>
        </div>
         
        <div className="flex flex-col items-center relative">
          <button
            className="flex flex-col items-center"
            onClick={() => handleNavigation("notify", "/modelnotify")}
          >
            <AiOutlineBell
              className={`w-8 h-8 ${
                activeTab === "notify" ? "text-white" : "text-gray-500"
              }`}
            />
            {notCount() > 0 && (
              <span className="absolute -top-1 right-4  bg-gray-500 text-black text-xs font-bold rounded-full border border-slate-800 px-1.5 py-0.5 shadow">
                {notCount()}
              </span>
            )}
          </button>
          <p className={` text-xs ${activeTab === "notify" ? "text-white" : "text-gray-500" }` }>Notifications</p>
        </div>
          
 
        <div className="flex flex-col items-center relative">
          <button
            className="flex flex-col items-center"
            onClick={() => handleNavigation("message", "/allmessage/recentmessage")}
          >
            <IoChatbubblesSharp
              className={`w-8 h-8 ${
                activeTab === "message" ? "text-white" : "text-gray-500"
              }`}
            />
            {checknotification() && (
              <p className="absolute w-5 h-5  bg-gray-500 text-black text-xs font-bold rounded-full border border-slate-800 px-1.5 py-0.5 shadow transform translate-x-3 -translate-y-3 m-auto">
                {messagecount()}
              </p>
            )}
          </button>
          <p className={` text-xs ${activeTab === "message" ? "text-white" : "text-gray-500" }` }>Messages</p>
        </div>
          </div>
         
          
        </div>
      )}
    </div>
  );
};

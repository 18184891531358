import React, {useEffect, useState, useRef} from 'react'
import profileIcon from "../icons/icons8-profile_Icon.png"
import uploadIcon from "../icons/uploadIcon.svg"
import { useSelector, useDispatch } from 'react-redux'
import PacmanLoader from "react-spinners/RingLoader";
import { getEdit, comprofilechangeStatus , updateEdit} from '../app/features/profile/comprofile';
import { downloadImage } from '../api/sendImage';
import { useNavigate } from 'react-router-dom';
import { Success } from '../fragment/Success';
import { Gennavigation } from '../navs/Gennav';
import HeaderBackNav from '../navs/HeaderBackNav';


export const Edit_profile = () => {

    const login = useSelector(state => state.register.logedin)
    const userid = useSelector(state => state.register.userID)
    const token = useSelector(state => state.register.refreshtoken)
    const data = useSelector(state=> state.comprofile.editData)
    const getedit_stats = useSelector(state=> state.comprofile.getedit_stats)
    const getedit_message = useSelector(state=> state.comprofile.getedit_message)
    const updateEdit_stats = useSelector(state=> state.comprofile.updateEdit_stats)
    const updateEdit_message = useSelector(state=> state.comprofile.updateEdit_message)
    let [loading, setLoading] = useState(true);
    let [color, setColor] = useState("#d49115");
    let [showEdit, set_showEdit] = useState(false)
    let navigate = useNavigate()
    let dispatch = useDispatch()
    let ref = useRef(null)

    
    let [firstnamepl ,setfirstnamepl] = useState("first name")
    let [lastnamepl ,setlastnamepl] = useState(" last name")
    let [countrypl ,setcountrypl] = useState("country")
    let [biopl ,setbiopl] = useState("about me")
    
    let [profileimg ,setprofileimg] = useState(profileIcon)

    let [firstname ,setfirstname] = useState("")
    let [lastname ,setlastname] = useState("")
    let [country ,setcountry] = useState("")
    let [bio ,setbio] = useState("")
   
    let [photolink ,setphotolink] = useState()
    let [deletePhotolink ,setdeletePhotolink] = useState()

    useEffect(()=>{
      if(!login){
       navigate("/")
      }

      if(login){
        if(getedit_stats !== "loading"){
          dispatch(getEdit({
            userid,
            token
          }))
        }
      }
    },[])

    useEffect(()=>{
      if(getedit_stats === "succeeded"){
        dispatch(comprofilechangeStatus("idle"))
        setLoading(false)
        set_showEdit(true)
        if(data){
          if(data.photolink){
            setprofileimg(downloadImage(data.photolink,"profile"))
            setdeletePhotolink(data.photolink)
          }
            if(data.firstname){
            setfirstnamepl(data.firstname)
          }
            if(data.lastname){
            setlastnamepl(data.lastname)
          }
            
            if(data.country){
            setcountrypl(data.country)
          }

          if(data.bio){
            setbiopl(data.bio)
          }
        }
      }

       if(getedit_stats === "failed"){
        dispatch(comprofilechangeStatus("idle"))
        console.log("edit message: "+getedit_message)
       
      }

      if(updateEdit_stats === "succeeded"){
         dispatch(comprofilechangeStatus("idle"))
        navigate("/")

      }

      if(updateEdit_stats === "succeeded"){
         dispatch(comprofilechangeStatus("idle"))
        setLoading(false)
        set_showEdit(true)
        
      }
    },[getedit_stats, updateEdit_stats])

    const updateButton = ()=>{
      if(!photolink && !firstname && !lastname && !country && !bio){
        return
      }

      if(updateEdit_stats !== "loading"){
        setLoading(true)
        set_showEdit(false)
        dispatch(updateEdit({userid,token,deletePhotolink, photolink:photolink, firstname,lastname,country,bio}))
      }
    }

  return (
    <div className="w-screen sm:w-11/12 md:w-10/12 lg:w-9/12 xl:w-8/12 mx-auto bg-black md:mr-auto md:ml-0">
      <div className='chat_nav'> <Gennavigation /></div>
      <HeaderBackNav/>
<div className="md:w-3/5 md:mx-auto">
      
       <div className='w-full  h-full flex flex-col items-center  md:w-3/4 mt-4 sm:mt-16 md:mr-auto md:ml-0'>
        <p className='text-slate-400 font-bold border border-b-2 border-t-0 border-r-0 border-l-0 border-slate-400'>Edit your Profile Information</p>

        {
          loading && 
            <div className='flex flex-col items-center mt-16 w-full'>
              <PacmanLoader
              color={color}
              loading={loading}
              size={30}
              aria-label="Loading Spinner"
              data-testid="loader"
              margin={ "auto"}
            />

            <p className='text-yellow-500 text-xs'>wait please...</p>
            </div>
          }

        {showEdit && <div className='w-full'>

           <div className='w-full flex flex-col items-center mt-1'> 
            <img alt='profileIcon' src={profileimg} className='w-16 h-16 rounded-full object-cover'></img>
            <button className='text-white font-bold  text-xs bg-orange-500 p-1 rounded-lg shadow shadow-white flex mt-1'
            onClick={(e)=>ref.current.click()}>
                <img alt='uploadIcon' src={uploadIcon} className='w-5 h-5'></img>
                <p>Upload</p>
            </button>
            <input type='file' accept='.png, .jpg, .jpeg' hidden ref={ref} onChange={(e)=>{
       
        
         if(e.target.files[0]){
           // setimglist(value => [...value,URL.createObjectURL(e.target.files[0])])
            setprofileimg(URL.createObjectURL(e.target.files[0]))
            setphotolink(e.target.files[0])
             
            }

        }}></input>

        </div>
 
        <div className='w-full flex flex-col ml-2 mt-3 text-slate-400 font-semibold'> 

         <div className='flex'> 
         <label>First Name</label>
         <input type='text' className='ml-2 rounded-lg bg-slate-400 text-slate-100 placeholder:text-slate-600 placeholder:font-normal placeholder:text-xs placeholder:text-center' placeholder={firstnamepl} onChange={(e)=>setfirstname(e.currentTarget.value)}></input>

         </div>

          <div className='flex mt-3'> 
         <label>Last Name</label>
         <input type='text' className='ml-2 rounded-lg bg-slate-400 text-slate-100  placeholder:text-slate-600 placeholder:font-normal placeholder:text-xs placeholder:text-center' placeholder={lastnamepl} onChange={(e)=>setlastname(e.currentTarget.value)}></input>

         </div>


          <div className='flex mt-3'> 
         <label>Bio</label>
         <textarea type='text' className='ml-2 rounded-lg bg-slate-400 text-slate-100 placeholder:text-slate-600 placeholder:font-normal placeholder:text-xs placeholder:text-center' placeholder={biopl} onChange={(e)=>setbio(e.currentTarget.value)}></textarea>

          </div>

        

           <div className='flex mt-3'> 
           <label>Country</label>
         <input disabled={true} type='text' className='ml-2 rounded-lg bg-slate-400 text-slate-100 placeholder:text-slate-600 placeholder:font-normal placeholder:text-xs placeholder:text-center' placeholder={countrypl} onChange={(e)=>setcountry(e.currentTarget.value)}></input>

          </div>

           <div className='flex mt-8 w-full p-2'> 
          <button className ="flex justify-center bg-orange-500 rounded-lg w-full text-center h-7" onClick={(e)=>updateButton()}>   
            <p className='text-slate-50'>update</p>
          </button>
       

          </div>

        </div>
          
        </div>}

       

       </div>
       </div>
    </div>
  )
}

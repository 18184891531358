import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate,Routes, Route } from "react-router-dom";
import "react-loading-skeleton/dist/skeleton.css";
import PacmanLoader from "react-spinners/RotateLoader";
import { Crush } from "./Crush";
import {Content} from "./Content"
import { getcollection, ProfilechangeStatus } from "../../app/features/profile/profile";
import HeaderBackNav from "../../navs/HeaderBackNav";

export const Collections = () => {
  const login = useSelector((state) => state.register.logedin);
  const collectionstats = useSelector((state) => state.profile.collectionstats);
  const navigate = useNavigate();
  const userid = useSelector((state) => state.register.userID);
  const token = useSelector((state) => state.register.refreshtoken);
  const dispatch = useDispatch()

  const [crushcolor, setcrushcolor] = useState("");
  const [contentcolor, setcontentcolor] = useState("#292d31");

  const [loading, setloading] = useState(true);
  let [color, setColor] = useState("#d49115");

  useEffect(()=>{
    if(loading === false){
      navigate("mycontent/");
    }
    
  },[loading])

  useEffect(()=>{
    if(collectionstats !== "loading"){
      dispatch(getcollection({userid,token}))
    }
  },[])

  useEffect(()=>{
    if(collectionstats === "succeeded"){
      setloading(false)
      dispatch(ProfilechangeStatus("idle"))
    }
  },[collectionstats])

  const checkfetch = ()=>{
    if(loading === false){
      return true
    }else{
      return false
    }
  }


  return (
    <div className="bg-black   ">

      <div className="sticky z-10 top-0">
        <HeaderBackNav title="Content & Crush Lists"/>
        <div className="grid grid-cols-2 gap-4 items-center pt-2">
          <button className="border-2 border-slate-300 text-center text-white py-1 rounded-md " style={{ backgroundColor: `${contentcolor}` }}
          onClick={(e)=>{
            setcontentcolor("#292d31");
            setcrushcolor("");
            navigate("mycontent/");
          }}
          >
            Purchased Content
          </button>

          <button className="border-2 border-slate-300 text-center text-white py-1 rounded-md "style={{ backgroundColor: `${crushcolor}` }}
          onClick={(e)=>{
            setcrushcolor("#292d31");
            setcontentcolor("");
             navigate("mycrush/");
          }}
          >
            Crush List
          </button>
        </div>
      </div>
      <div className="text-slate-200 mt-2">

      {loading && (
      <div className="w-full flex flex-col items-center mt-10">
        <PacmanLoader
          color={color}
          loading={loading}
          size={10}
          aria-label="Loading Spinner"
          data-testid="loader"
        />

        <p className="text-xs">Fetching Data Please wait...</p>
      </div>
      )}
        <div className="overflow-auto pb-7"></div>
        { checkfetch() && ( <Routes>
            <Route path="mycontent/" element={<Content />} />
            <Route path="mycrush/" element={<Crush />} />
          </Routes>)}
        </div>
        
      </div>
  );
};
